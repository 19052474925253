body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: Avenir, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.Content {
  background-color: white;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  margin-top: calc(-1 * (4px + 2vmin));
}

p, label {
  font-size: calc(4px + 2vmin);
}

.line {
  width: 100%;
  border-style: ridge;
}

.Name {
  margin-bottom: 0px;
}

.SocialLinks {
  list-style: none;
  padding: 0;
  margin-top: .5em;
}

.SocialLinks>li {
  display: inline-block;
  margin: 0 .5rem;
}

.Icon {
  color: black;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

